@import "~bootstrap/scss/bootstrap";

.no-resize {
  resize: none;
}

.center-screen {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.btn-circle {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  text-align: center;
  font-size: 24px;
  line-height: 1.42857;
}